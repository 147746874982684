



























































































































































































































































































































































































































































import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RosterService from "@/services/RosterService";
import LocationService from "@/services/LocationService";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";
import AuthService from "@/services/AuthService";

@Component
export default class Roster extends Vue {
  private editor = ClassicEditor;
  private timetable: any[] = [];
  private locations: any[] = [];
  private services: any[] = [];
  private uuid: string = "";
  private downloadLocationUuid: string = "";
  private approveAllStatus: string = "CLOSED";
  private startDate: any = null;
  private endDate: any = null;
  private locationUuid: string = router.currentRoute.query.location as string;
  private rosterUuid: string = router.currentRoute.query.roster as string;
  private month: string = router.currentRoute.query.date as string;
  private roster: any = {};
  private hasChanged: boolean = false;
  private status: any = {
    YES: {
      icon: "check-square",
      variant: "success",
      legend: "Teilnahme zugesagt",
    },
    NO: { icon: "x-circle", variant: "danger", legend: "Teilnahme abgesagt" },
    UNKNOWN: {
      icon: "dash-square",
      variant: "secondary",
      legend: "Keine Antwort",
    },
  };
  private approval: any = {
    OPEN: {
      variant: "success",
      legend: "offen",
      action: "öffnen",
      message: "Anmeldung ist möglich",
    },
    CLOSED: {
      variant: "danger",
      legend: "geschlossen",
      action: "schließen",
      message: "Anmeldung ist geschlossen",
    },
  };

  fieldsAll() {
    const f = [
      { key: "event", label: "Termin" },
      { key: "approval", label: "Status" },
      { key: "status", label: "Dienste" },
      { key: "edit", label: "" },
    ];

    return f;
  }

  fieldsLocation() {
    const f = [
      ...[
        { key: "event", label: "Termin" },
        { key: "approval", label: "Status" },
      ],
      ...this.allServices().map(
        (s) =>
          <any>{
            key: s,
          }
      ),
      ...[{ key: "edit", label: "" }],
    ];

    return f;
  }

  colwidths() {
    const w = 60 / this.allServices().length;
    var width = [20, 10];

    this.allServices().forEach(() => width.push(w));

    width.push(10);

    return width;
  }

  allServices() {
    var services = new Set<string>();

    this.timetable.forEach((t) =>
      t.item.services.forEach((s: string) => services.add(s))
    );

    return [...services];
  }

  hasService(service: string) {
    return (
      this.timetable.filter(
        (r) =>
          r.registrations.filter((s: any) => s.user.services.includes(service))
            .length > 0
      ).length > 0
    );
  }

  register(roster: any, item: any, service: string) {
    RosterService.book(roster.event.uuid, this.date2(roster.date), <any>{
      uuid: item.uuid,
      services: [service],
      username: item.user.username,
    }).then((response) => {
      this.timetable = this.timetable.map((r) => {
        if (r.uuid === roster.uuid) {
          r.registrations.forEach((x: any) => {
            if (x.uuid === item.uuid) {
              x.services = response.data.services;
            }
          });
        }
        return r;
      });
    });
  }

  colclass(roster: any) {
    if (roster.item.status === "CANCELLED") {
      return "event_cancelled";
    }

    return roster.selected ? "selected" : "";
  }

  save() {
    RosterService.save(
      this.roster.uuid,
      this.roster.item.event.uuid,
      this.roster.date,
      {
        description: this.roster.description,
        approval: null,
      }
    ).then((response) => (this.hasChanged = false));
  }

  approve() {
    RosterService.save(
      this.roster.uuid,
      this.roster.item.event.uuid,
      this.roster.date,
      {
        description: null,
        approval: this.roster.approval,
      }
    ).then((response) =>
      this.$bvToast.toast(
        `${this.roster.item.event.name} ${this.date(this.roster.date)} ${
          this.roster.item.startTime
        }`,
        {
          title: this.approval[this.roster.approval].message,
          variant: this.approval[this.roster.approval].variant,
          autoHideDelay: 3000,
          appendToast: true,
        }
      )
    );
  }

  update(event: any) {
    this.hasChanged = true;
  }

  change(event: any) {
    this.reload(this.uuid, event);
  }

  selectLocation(locationUuid: any) {
    this.reload(locationUuid, this.month);
  }

  selectRoster(roster: any) {
    if (this.roster.uuid === roster.uuid) {
      return;
    }

    if (roster.item.status === "CANCELLED") {
      this.roster = {};
      return;
    }

    this.timetable.forEach((r) => (r.selected = false));

    RosterService.load(roster.uuid, roster.item.event.uuid, roster.date).then(
      (response) => {
        const i = this.timetable.map((r) => r.uuid).indexOf(roster.uuid);
        this.roster = this.timetable[i];
        Object.assign(this.roster, response.data);
        this.roster.selected = true;
        router.replace({
          name: "roster",
          query: {
            location: this.uuid,
            roster: this.roster.uuid,
            date: this.month,
          },
        });
      }
    );
  }

  clear() {
    this.roster = {};
    this.timetable.forEach((r) => (r.selected = false));
    router.replace({
      name: "roster",
      query: { location: this.uuid, date: this.month },
    });
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  reload(locationUuid: string, date: string) {
    this.timetable = [];
    this.uuid = locationUuid || "";
    this.month = date || this.date2(new Date().setDate(1));

    LocationService.all("name").then((loc) => {
      this.locations = loc.data.content;
    });
    RosterService.roster(this.uuid, this.month).then((response) => {
      this.timetable = response.data;
      this.roster =
        this.timetable.filter((r) => r.uuid === this.rosterUuid)[0] || {};
      this.timetable.forEach((r) => (r.selected = r.uuid === this.roster.uuid));
      router.replace({
        name: "roster",
        query: { location: this.uuid, date: this.month },
      });
    });
  }

  initDownload() {
    const d = new Date(this.month);
    d.setMonth(d.getMonth() + 1);
    d.setDate(d.getDate() - 1);

    this.startDate = this.month;
    this.endDate = this.date2(d);
    this.downloadLocationUuid = this.uuid;
    this.$bvModal.show("download");
  }

  download() {
    RosterService.download(
      this.downloadLocationUuid,
      this.startDate,
      this.endDate
    ).then((response: any) => {
      var downloadURL = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const anchor = document.createElement("a");
      anchor.download = `Dienstplan-${
        this.locations.filter((l) => l.uuid === this.downloadLocationUuid)[0]
          .name
      }-${moment(this.month).format("YYYY-MM")}.pdf`;
      anchor.href = downloadURL;
      anchor.target = "_blank";
      anchor.click();
    });
  }

  initSend() {
    const d = new Date(this.month);
    d.setMonth(d.getMonth() + 1);
    d.setDate(d.getDate() - 1);

    this.startDate = this.month;
    this.endDate = this.date2(d);
    this.downloadLocationUuid = this.uuid;
    this.$bvModal.show("send");
  }

  send() {
    RosterService.send(
      this.downloadLocationUuid,
      this.startDate,
      this.endDate
    ).then((response: any) => {});
  }

  initApprove() {
    const d = new Date(this.month);
    d.setMonth(d.getMonth() + 1);
    d.setDate(d.getDate() - 1);

    this.startDate = this.month;
    this.endDate = this.date2(d);
    this.downloadLocationUuid = this.uuid;
    this.$bvModal.show("approveAll");
  }

  approveAll() {
    RosterService.approve(
      this.downloadLocationUuid,
      this.startDate,
      this.endDate,
      { approval: this.approveAllStatus }
    ).then((response: any) =>
      this.reload(this.downloadLocationUuid, this.startDate)
    );
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    AuthService.user().then((response) => {
      this.services = response.data.services;
      this.reload(this.locationUuid || response.data.location, this.month);
    });
  }
}
